import { ServiceHandlerFactory } from '../utils/serviceUtils';
import { Api } from '../config/axiosConfig';
import { formsServiceMappings } from '../console/support/formsLibrary/formsServiceMappings';
import { commonDateParamMappings } from './common/mappings';

export const getDocuments = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/search/:accountId',
  },
  paramSchema: [
    ...commonDateParamMappings,
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      path: 'docType',
      transform: ({ docType = [] }) => {
        return docType?.join(', ');
      },
    },
    {
      path: 'agencyId',
    },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'uploadTime':
            return `${orderBy}.timestamp,${order}`;
          case 'systemGenerated':
          case 'docTime':
          case 'modified':
            return `${orderBy},${order}`;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});
export const getPHDocuments = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/search',
  },
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'uploadTime':
            return `${orderBy}.timestamp,${order}`;
          case 'systemGenerated':
          case 'modified':
            return `${orderBy},${order}`;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});

export const getDocumentsByAgencyId = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/search/:agencyId',
  },
  paramSchema: [
    { path: 'page' },
    { path: 'size' },
    { path: 'search' },
    {
      paramKey: 'sort',
      transform: ({ orderBy, order }) => {
        switch (orderBy) {
          case 'uploadTime':
            return `${orderBy}.timestamp,${order}`;
          case 'systemGenerated':
          case 'modified':
            return `${orderBy},${order}`;
          default:
            return orderBy ? `${orderBy}.keyword,${order}` : null;
        }
      },
    },
  ],
});

export function uploadDocument(
  data,
  accountId,
  docType,
  docName,
  temp,
  agencyId
) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return Api.post(
    `/api/docs/v1/upload/doc?accountId=${accountId}&docType=${docType}&docName=${docName}&temp=${temp}&agencyId=${agencyId}`,
    data,
    config
  );
}

export const uploadDocumentV2 = ServiceHandlerFactory({
  axios: {
    url: `/api/docs/v1/upload/doc`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
  },
});

export const updateNotice = ServiceHandlerFactory({
  axios: {
    url: ' /api/account/v1/agency/surplus/notice/upload',
    method: 'post',
  },
});

export const updateDocument = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/update',
    method: 'put',
  },
});

export const downloadDocument = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/download?docId=:docId',
  },
});

export const downloadBullpenDocument = ServiceHandlerFactory({
  axios: {
    url: '/api/claimsmgmt/v1/claimsFile/download?claimsFileId=:claimsFileId',
  },
});

export const deleteDocumentById = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1?docId=:docId',
    method: 'delete',
  },
});

export const getDocTypes = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/docTypes',
  },
});

export const uploadNOC = ServiceHandlerFactory({
  axios: {
    url: 'api/policy/v1/noc/:policyId',
    method: 'put',
  },
});

// ==========================================
//           Forms Library Services
// ==========================================

export const getForms = ServiceHandlerFactory({
  axios: { url: '/api/docs/v1/forms/search' },
  paramSchema: formsServiceMappings,
});

export const getFormById = ServiceHandlerFactory({
  axios: { url: '/api/docs/v1/forms/id/:id' },
});

export const getAllForms = ServiceHandlerFactory({
  axios: { url: '/api/docs/v1/forms/search?size=2000' },
  paramSchema: formsServiceMappings,
});

export const createFormTemplate = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/forms',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },
});

export const deleteForm = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/forms/:id',
    method: 'DELETE',
  },
});

export const updateFormTemplate = ServiceHandlerFactory({
  axios: {
    url: 'api/docs/v1/forms/:id',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  },
});

export const formAddDocInfo = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/forms/doc/:id',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  },
});

export const getSupportedPaperTypes = ServiceHandlerFactory({
  axios: { url: '/api/docs/v1/forms/papertypes' },
});

export const getProductTypes = ServiceHandlerFactory({
  axios: { url: '/api/docs/v1/forms/producttypes' },
});

export const getFormTypes = ServiceHandlerFactory({
  axios: { url: '/api/docs/v1/formtype/search' },
});

export const createFormType = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/formtype',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },
});

export const updateFormType = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/formtype/:id',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  },
});

export const deleteFormType = ServiceHandlerFactory({
  axios: {
    url: '/api/docs/v1/formtype/:id',
    method: 'DELETE',
  },
});
