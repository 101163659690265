import _ from 'lodash';

import { blueGrey } from '@mui/material/colors';

import Color from 'color';
import { adaptV4Theme, createTheme } from '@mui/material';
import _config from './_base/_config';

import _base from './_base/_base';
import _baseOverrides from './_base/_base.overrides';
import _baseGenerics from './_base/_base.generics';

// prop overrides
import MuiCardProps from './_props/MuiCard.props';

const { colors } = _config;

export const themebase = {
  config: _config,

  images: {
    wizerTraining: {
      logo: 'images/wizerLogoLight.png',
    },
  },

  palette: {
    mode: 'light',
    common: colors,
    divider: colors.darkGreySoft,
    primary: {
      light: colors.lightCyan,
      main: colors.cyan,
      dark: colors.aquaMarine,
      contrastText: colors.darkGrey,
      greyCard: '#262626',
      border: '#707070',
      oldBorder: '#c9cdd9',
      title: colors.cowbellLight,
      green: colors.good,
      mainYellow: colors.yellow,
      yellow: colors.elevated,
      red: colors.critical,
      modalBorder: colors.lightGrey3,
      border1: '#91949d',
      border2: colors.cowbellLight,
      border3: colors.darkGreySoft,
      border4: colors.cowbellAccent,
      border5: colors.faintBrown,
      border6: colors.darkGrey,
      border7: colors.green3,
      yellow1: colors.yellow1,
      green1: colors.green1,
      aquaMarine1: colors.aquaMarine1,
      purple: colors.purple,
      brown: colors.brown,
      grey: colors.greyWhite,
      loadingIcon: colors.cowbellBlue,
      favoriteIcon: colors.favoriteOrange,
      cowbellBlue: colors.cowbellBlue,
    },

    tabs: {
      profile: colors.cowbellBlue,
      question: colors.lightBlue,
    },

    secondary: {
      light: blueGrey[400],
      main: blueGrey[500],
      dark: blueGrey[600],
      contrastText: colors.white,
    },
    active: {
      menu: colors.blueWhiteActive,
    },
    success: {
      main: colors.paleGreen,
      contrastText: colors.white,
    },
    checkbox: {
      secondary: colors.cowbellAccent,
    },
    breadcrumbs: {
      text: colors.darkGrey3,
    },

    chip: {
      border: '#0055a2',
      selection: '#E7EFF6',
      color: colors.darkGrey,
    },

    error: {
      light: colors.paleTomato,
      main: colors.error,
      dark: colors.ripeTomato,
      contrastText: colors.white,
      errorText: colors.ripeTomato,
      darkRed: colors.blood,
      lightRed: colors.bloodLight,
    },

    warning: {
      light: colors.lightCanery,
      main: colors.canery,
      dark: colors.darkCanery,
      contrastText: colors.darkGreySoft,
    },
    icon: {
      primary: colors.darkGreySoft,
      secondary: colors.darkGrey3,
    },
    text: {
      primary: colors.darkGrey3,
      mfa: colors.cowbellCyan,
      secondary: colors.dark,
      title: colors.greyWhite,
      hover: colors.darkGreyActive,
      disabled: colors.mediumGrey,
      hint: '',
      icon: colors.darkGreySoft,
      link: colors.darkGreySoft,
      link1: colors.cowbellLight1,
      placeholder: colors.darkGreySoft,
      highlighter: colors.darkGrey,
      asterisk: colors.orangeRed,
      text3: colors.cowbellAccent,
      text4: colors.cowbellGreen2,
      text5: '#fa8072',
      text6: colors.almostWhite,
      message: colors.cowbellLight,
      white1: colors.white1,
      text7: colors.darkGrey3,
      text8: colors.dark1,
      text9: colors.textRed,
      text10: colors.blue3,
      text11: colors.faintBrown,
      contrastText: colors.dark,
      text12: colors.darkGrey3,
      text13: colors.white,
      text14: colors.dark,
      text15: colors.dark,
      text16: colors.darkGrey3,
      text17: colors.white3,
      highlightedtext: colors.lightBlue,
    },
    email: {
      primary: colors.blue1,
    },

    background: {
      default: colors.bbb,
      paper: colors.white,
      autocompleteOptionFocus: '#d3e1f2',
      modal: colors.lightGrey1,
      lighter: colors.eggWhite,
      active: Color(colors.babyBlueGrey).darken(0.02).string(),
      public: colors.bbb,
      tertiary: Color(colors.babyBlueGrey).lighten(0.5).string(),
      highlighter: Color(colors.cyan).lighten(0.75).string(),
      button: colors.cowbellLight,
      tableHeader: colors.blueWhiteHard,
      tableRowHover: colors.paleGrey,
      tableRowDisabled: colors.lightGrey,
      tableRow: colors.white,
      tableBody: colors.bbb,
      white: colors.bbb,
      yellow: colors.yellow,
      darkerBlue: colors.darkBlue,
      powderBlue: colors.powderBlue,
      paleBlue: colors.paleBlue,
      blue5: colors.blue5,
      green: colors.cowbellAccent,
      green1: colors.green3,
      green4: colors.green4,
      green5: colors.cowbellGreen,
      lightGreen: colors.lightGreen,
      grey3: colors.grey3,
      red: colors.textRed,
      dark: colors.black1,
      white1: colors.darkGrey3,
      activeIcon: colors.dark,
      usersBox: colors.blueWhiteHard,
      darkGrey1: colors.darkGrey1,
      darkGrey2: colors.darkGrey4,
      disabled: colors.grey11,
      greyBlack: colors.lightGrey,
      darkBlack: '#ffffff',
      greyBlackLight: '#ffffff',
      customTable: colors.paleGrey,
      lightNavy: colors.powderBlue,
      payment: colors.veryLightGrey,
      accountLookup: colors.blueWhiteHard,
      connectorHover: colors.blueWhiteActive,
      connectorQuickView: colors.blueWhiteActive,
      oddRow: colors.blueWhiteActive,
      details: colors.bbb,
      iframe: colors.blueWhiteHard,
      fileUploader: colors.blue4,
      salesInboxAccordion: colors.greyWhite,
      darkB: colors.lightGrey,
      radioButtonSelected: colors.greyWhite,
      radioButton: colors.white,
      uniqueRow: colors.white2,
      buttonGroup: colors.almostWhite,
      contrastText: colors.black,
    },

    drawer: {
      background: colors.bbb,
      rowsOdd: colors.darkGreySoft,
      rowsEven: colors.white,
    },

    /* components */
    tables: {
      selectedRow: colors.lightGrey,
      shimmer: 'rgba(200, 200, 200, .15)',
      productRow: colors.tableRowLight,
      billedRow: colors.lightWhite,
      endorsement: colors.darkCyan,
    },

    notification: {
      list: colors.bbb,
      background: colors.eggWhite,
      border: colors.paleGrey1,
      header: colors.veryLightGrey,
      lowerText: colors.greyBlack1,
      hoverHeader: colors.veryLightGrey1,
      hoverBody: colors.veryLightGrey2,
      containerBorder: colors.whiteBorder,
      clear: colors.bbb,
      hover: colors.paleGrey,
    },
    ticker: {
      barcolor: colors.lightGrey3,
    },

    graphs: {
      bar: {
        primary: '#d0d0d0',
      },
      pie: {
        primary: colors.cowbellLight,
        secondary: colors.darkGreyLighter,
        primaryBlue: colors.cowbellLight,
        secondaryBlue: colors.powderBlue,
        primaryGreen: colors.cowbellAccent,
        secondaryGreen: colors.cowbellGreen,
      },
      line: colors.paleGrey1,
    },

    calendar: {
      headerText: colors.white,
      borderColor: colors.darkGreySoft,
      presetBackground: colors.babyBlueGrey,
      presetHoverBackground: colors.lightGrey,
      presetHoverText: colors.darkGreySoft,

      native: {
        selectionColor: colors.aquaMarine,
        weekdayColor: colors.lightAquaMarine,
        headerColor: colors.aquaMarine,
        todayColor: colors.lightAquaMarine,
        accentColor: colors.lightAquaMarine,
        floatingNav: {
          background: colors.lightGrey,
          color: colors.darkGreySoft,
          chevron: 'transparent',
        },
      },
    },

    select: {
      disabled: 'rgba(59, 59, 59, 0.5)',
    },

    components: {
      globalHeader: {
        background: '#586d79',
        contrastText: colors.dark,
        paper: 'hsl(204, 15.2%, 91.7%)',
      },
    },
    badge: {
      icon: colors.badgeRed,
    },
    border: {
      menuItem: colors.darkblack,
      icon: colors.black,
      grey: colors.lightGrey1,
      acceptFileUploader: colors.blue4,
      soft: colors.softBlack,
    },
    snackBar: {
      primary: colors.snackGreen,
    },
    button: {
      text: colors.dark1,
      text2: colors.white,
      border: colors.dark1,
      background: colors.grey7,
      background2: colors.white2,
      disabledBorder: colors.grey5,
      disabledText: colors.grey5,
      disabledBackground1: colors.white2,
      disabledBackground: colors.grey6,
    },

    connectors: {
      legend1: colors.blue2,
      legend2: colors.green2,
      legend3: colors.pink1,
      legend4: colors.powderBlue,
    },
    apiKeys: {
      row: colors.lightGrey2,
    },

    flags: {
      icon: '#fff',
      program: '#78A8E6',
      api: '#E5AB64',
      unverified: '#B08EE5',
      openClaim: '#E98477',
      closedClaim: '#9DDA8D',
      raterX: '#E678AF',
      raterXIcon: '#fff',
    },
  },
  jsonPalette: {
    base00: colors.eggWhite, // background
    base01: '#444',
    base02: colors.darkGreySoft,
    base03: colors.darkGreySoft,
    base04: colors.darkGreySoft,
    base05: '#444',
    base06: '#444',
    base07: colors.darkGreySoft,
    base08: '#444',
    base09: colors.darkGreySoft,
    base0A: colors.darkGreySoft,
    base0B: colors.darkGreySoft,
    base0C: colors.darkGreySoft,
    base0D: colors.darkGreySoft,
    base0E: colors.darkGreySoft,
    base0F: colors.darkGreySoft,
  },
  connectors: {
    icons: {
      'Cowbell Cyber': 'CowbellLogo2.svg',
      'Microsoft Secure Score': 'Microsoft365B.svg',
      'GCP Security Command Center': 'GoogleCloudB.svg',
      'AWS Security Hub': 'AWSW.svg',
      AWS: 'AWSW.svg',
      'Secureworks ': 'Secureworks_Black.svg',
      'Security Studio': 'securityStudio_light.svg',
      'Qualys Vulnerability Management': 'Qualys.svg',
      'Qualys Policy Compliance': 'Qualys.svg',
      'Qualys Container Security': 'Qualys.svg',
      'Wizer Cyber Training': 'WizerSmall_LightLogo.svg',
      'Safeguard Cyber': 'SafeGuard_Small_LightTheme.svg',
      'Google Workspace': 'GoogleWorkspace.svg',
      CLOUDFLARE: 'connectors/Cloudflare.svg',
      'SOPHOS ENDPOINT': 'connectors/Sophos.svg',
    },
  },
  agencyDash: {
    icon: '#0055a2',
    interactable: '#0055a2',
    background: {
      default: '#f9f9f9',
      tableRow: '#fff',
      contrast: '#edeff1',
      menu: '#fff',
      paper: '#fff',
      button: '#fff',
      header: '#90a4ab',
      hover: '#dedede',
      divider: '#0000001f',
      container: '#fff',
      tooltip: '#2282e3',
    },
    border: {
      default: '#d2d8db',
      divider: '#d2d8db',
    },
    tabs: {
      selected: '#0055a2',
      default: '#eceff1',
      hover: '#0055a2',
    },
    text: {
      link: colors.darkGrey3,
      hyperlink: colors.darkGrey3,
      error: '#b00020',
      warning: '#fbc000',
      primary: '#09090c',
      secondary: '#95a2ad',
      tab: '#fff',
      tooltip: '#fff',
    },
    buttons: {
      startNewQuote: '#7c96bf',
      renewPolicy: '#abcbfc',
      bindQuote: '#c2abfc',
      viewCommissions: '#bf7ca8',
    },
    statsBar: {
      expiringQuotes: '#ff7e75',
      expiringBinders: '#ffb4ab',
      policiesUpForRenewal: '#d1dff6',
    },
    group: {
      border: '#F1F1F2',
      background: '#FBFBFC',
    },
  },
} as const;

const themeoverrides = {
  MuiCard: {
    root: {
      background: themebase.palette.background.lighter,
    },
  },

  MuiFormControlLabel: {
    label: {
      color: `${themebase.palette.text.text8}`,
      fontSize: _config.textSizes.normal,
      fontFamily: _config.fonts.stackSans,
    },
    root: {
      marginRight: '1rem',
    },
  },
  MuiInputBase: {
    input: {
      color: colors.dark1,
      WebkitTextFillColor: colors.dark1,
      // border: 'inherit',

      '&:-webkit-autofill': {
        // WebkitBoxShadow: `0 0 0 100px ${themebase.palette.background.active} inset`,
        WebkitTextFillColor: colors.dark1,
      },

      '&.Mui-disabled': {
        // background: colors.grey11,
        color: colors.dark1,
        WebkitTextFillColor: colors.dark1,
      },
    },
  },

  MuiTabs: {
    indicator: {
      backgroundColor: themebase.palette.primary.main,
    },
  },

  MuiTab: {
    root: {
      '&.Mui-selected': {
        color: 'white',
      },
    },
  },
  button: {
    '&:hover': {
      background: 'red',
    },
  },
  MuiSelect: {},

  MuiPaper: {
    elevation1: {
      boxShadow: 'none',
    },
    elevation6: {
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
  },

  MuiToggleButton: {
    root: {
      color: themebase.palette.text.primary,
      '&.Mui-selected': {
        backgroundColor: themebase.palette.background.default,
        color: themebase.palette.primary.contrastText,
      },

      '&.Mui-selected:hover': {
        backgroundColor: `${themebase.palette.background.default} !important`,
      },
    },
  },
} as const;

export const lightThemebaseV4 = {
  ..._.merge({}, _base, themebase),
  overrides: _.merge(
    {},
    _baseOverrides({
      themebase,
      // @ts-expect-error theme needs redesigned and migrated to V5
      primaryText: themebase.palette.text.primary,
      borderColor: themebase.palette.background.paper,
      tableHeaderBackground: themebase.palette.background.tableHeader,

      inputBaseStyles: {
        background: themebase.palette.background.default,
        borderColor: themebase.palette.common.darkGreySoft,

        // '&:focus': {
        //   background: themebase.palette.common.darkBlue,
        //   color: 'black',
        // },
      },
    }),
    themeoverrides
  ),
  props: {
    ...MuiCardProps,
  },
  globals: _baseGenerics, // global styling for resuable components
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 12,
    fontFamily: [
      'Roboto',
      'Helvetica, Arial',
      '"Arial Unicode"',
      '"Lucida Sans Unicode"',
      'sans-serif',
    ].join(','),
  },
};

// @ts-expect-error theme needs redesigned and migrated to V5
export default createTheme(adaptV4Theme(lightThemebaseV4));
