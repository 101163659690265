import type { AxiosRequestConfig } from 'axios';
import {
  applyQueryConfig,
  responseStringTransformer,
  serializeStandardResponse,
  ServiceHandler,
} from '../utils/api.utils';
import type { PageableResponse } from './serializers';
import { standardQuerySerializer } from './serializers';

import {
  quoteSearchV2Mappings,
  quoteServiceMappings,
} from '../inbox/quoteServiceMappings';

import {
  pullFactorsFromCowbellRiskFactors,
  serializeCowbellFactors,
} from './serializers/cowbell-factors.serializers';
import type { ProductType } from '../types';
import { isPrimeX, ProductTypes } from '../types';
import { transformQuoteDetailsResponse } from './serializers/quote.serializers';
import type {
  CheckStatusOnAccountResponse,
  DeleteQuoteFromCartResponse,
  DynamicFields,
  FetchUWInboxStatsInfoResponse,
  GetPrimeXQuoteDetailsResponse,
  GetPxRaterResponse,
  GetQuoteCartListResponse,
  GetQuoteProgress,
  UpdateQuoteCartListPayload,
} from './quotes.api.types';
import { PERSONA_TYPES } from '../console/_statics/persona.statics';
import type { QuoteSearchRowDto } from '../types/quotes/tables/quote-table-row.dto';
import {
  serializeLegacyIndustryCode,
  serializePxQuoteIndustryCode,
} from './serializers/industry-code.serializers';

type SearchQuotesResponse = PageableResponse<QuoteSearchRowDto>;
export const searchQuotes = ServiceHandler<unknown, SearchQuotesResponse>({
  url: '/api/quote/v1/search',
  paramsSerializer: (params) =>
    standardQuerySerializer(quoteSearchQuerySerializer(params)),
});

export const getP250QuotesDelta = ServiceHandler({
  url: 'api/quote/v1/P250/delta',
  method: 'POST',
});

/* specific serializers */
function quoteSearchQuerySerializer(params: any) {
  return applyQueryConfig(quoteServiceMappings, params);
}

export const getQuoteP100 = ServiceHandler({
  url: '/api/quote/v1/:quoteId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    serializeLegacyIndustryCode,
  ],
});

export const getQuoteP250 = ServiceHandler({
  url: '/api/quote/v1/P250/quotes/:quoteId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    serializeLegacyIndustryCode,
  ],
});

export const getQuoteDetailsP250 = ServiceHandler({
  url: '/api/quote/v1/P250/details/:quoteId',
  transformResponse: [
    responseStringTransformer,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
  ],
});

export const getStepperQuoteDetails = ServiceHandler({
  url: '/api/quote/v1/:productType/:quoteId?view=full',
  transformResponse: [serializeStandardResponse, serializePxQuoteIndustryCode],
});

export const getPrimeXQuoteDetails = ServiceHandler({
  url: '/api/quote/v1/:productType/:quoteId',
  transformResponse: [serializeStandardResponse, serializePxQuoteIndustryCode],
});

export const getPrimeXAccordionQuoteDetails = ServiceHandler<
  unknown,
  GetPrimeXQuoteDetailsResponse
>({
  url: '/api/quote/v1/:productType/:quoteId?view=standard',
  transformResponse: [
    serializeStandardResponse,
    transformQuoteDetailsResponse,
    pullFactorsFromCowbellRiskFactors,
    serializeCowbellFactors,
    serializePxQuoteIndustryCode,
  ],
});

export const getQuoteDetails = (id: string, product: string) => {
  switch (product) {
    case ProductTypes.p100:
      return getQuoteP100(id);
    case ProductTypes.p250:
      return getQuoteP250(id);
    default:
      return getPrimeXAccordionQuoteDetails(product, id);
  }
};

export const saveQuoteSurplusData = ServiceHandler({
  url: '/api/quote/v1/action/update/surplusData',
  method: 'POST',
});

export const getQuoteProgress = ServiceHandler<unknown, GetQuoteProgress>({
  url: '/api/quote/v1/:quoteId/progress',
});

export const getQuoteSubjectivityProgress = ServiceHandler({
  url: '/api/quote/v1/:productType/quote-subjectivity/:quoteSubjectivityId',
});

export const completeQuoteSubjectivity = ServiceHandler({
  url: '/api/quote/v1/:productType/quote-subjectivity/:quoteSubjectivityId/complete',
  method: 'POST',
});

export type IssuePolicyPersonas =
  | typeof PERSONA_TYPES.AGENCY
  | typeof PERSONA_TYPES.COWBELL;

export const issuePolicy = ({
  persona,
  productType,
  quoteId,
}: {
  persona: IssuePolicyPersonas;
  productType: ProductType;
  quoteId: string;
}) => {
  if (isPrimeX(productType) || persona === PERSONA_TYPES.COWBELL) {
    return primeXIssuePolicy(productType, quoteId);
  }

  return agentIssuePolicy(quoteId);
};

export const primeXIssuePolicy = ServiceHandler({
  url: '/api/quote/v1/:productType/issue/:quoteId',
  method: 'PUT',
});

export const agentIssuePolicy = ServiceHandler({
  url: '/api/quote/v1/:quoteId/agent-issue',
  method: 'PUT',
});

export const createManuscriptSubjectivity = ServiceHandler({
  url: '/api/quote/v1/subjectivity/manuscript',
  method: 'POST',
});

export const changeQuoteStatusToReferred = ServiceHandler({
  url: '/api/quote/v1/:productType/uw/refer/:quoteId',
  method: 'PUT',
});

export const downloadSpecimenDoc = ServiceHandler({
  url: '/api/docs/v1/downloadSpecimen',
});

export const fetchUWInboxStatsInfo = ServiceHandler<
  unknown,
  FetchUWInboxStatsInfoResponse
>({
  url: '/api/quote/v1/search/uw-inbox/aggregate',
  paramsSerializer: (params) =>
    standardQuerySerializer(quoteSearchQuerySerializer(params)),
});

export const checkStatusOnAccount = ServiceHandler<
  unknown,
  CheckStatusOnAccountResponse
>({
  url: '/api/policy/v1/business/:accountId',
});

export const getQuoteCartList = (
  product: ProductType,
  accountId: string,
  submissionId: string | undefined,
  config: AxiosRequestConfig
) => {
  if (submissionId) {
    return getQuoteCartListForSubmission(product, submissionId, config);
  }
  return getQuoteCartListForAccount(product, accountId, config);
};

export const getQuoteCartListForAccount = ServiceHandler<
  unknown,
  GetQuoteCartListResponse
>({
  url: '/api/quote/v1/:productType/cart/account/:accountId',
});

export const getQuoteCartListForSubmission = ServiceHandler<
  unknown,
  GetQuoteCartListResponse
>({
  url: '/api/quote/v1/:productType/cart/submission/:submissionId',
});

export const updateQuoteCartList = (
  product: ProductType,
  accountId: string,
  submissionId: string | undefined,
  config: AxiosRequestConfig
) => {
  if (submissionId) {
    return updateQuoteCartListForSubmission(product, submissionId, config);
  }
  return updateQuoteCartListForAccount(product, accountId, config);
};

export const updateQuoteCartListForAccount = ServiceHandler<
  UpdateQuoteCartListPayload,
  unknown
>({
  url: '/api/quote/v1/:productType/cart/account/:accountId',
  method: 'POST',
});

export const updateQuoteCartListForSubmission = ServiceHandler<
  UpdateQuoteCartListPayload,
  unknown
>({
  url: '/api/quote/v1/:productType/cart/submission/:submissionId',
  method: 'POST',
});

export const reviveQuote = ServiceHandler({
  url: '/api/quote/v1/da/P250/revive/:quoteId',
  method: 'PUT',
});

export const deleteQuoteFromCartList = ServiceHandler<
  unknown,
  DeleteQuoteFromCartResponse
>({
  url: '/api/quote/v1/:productType/cart/:quoteId',
  method: 'DELETE',
});

export const getRaterCalculator = ServiceHandler<any, GetPxRaterResponse>({
  url: '/api/rater/v1/:productType/calculator',
  method: 'POST',
});

export const bulkRequestQuotesFromCart = (
  product: ProductType,
  accountId: string,
  submissionId: string | undefined,
  config: AxiosRequestConfig
) => {
  if (submissionId) {
    return bulkRequestQuotesFromCartForSubmission(
      product,
      submissionId,
      config
    );
  }
  return bulkRequestQuotesFromCartForAccount(product, accountId, config);
};

export const bulkRequestQuotesFromCartForAccount = ServiceHandler({
  url: '/api/quote/v1/:productType/cart/account/:accountId/submit',
  method: 'POST',
});

export const bulkRequestQuotesFromCartForSubmission = ServiceHandler({
  url: '/api/quote/v1/:productType/cart/submission/:submissionId/submit',
  method: 'POST',
});

export const bulkApplyP100QuoteToAccounts = ServiceHandler({
  url: '/api/quote/v1/bulk/quoteAccounts/:quoteId',
  method: 'POST',
});

export interface ValidateAccountProfileResponse {
  pass: boolean;
  errorMessage?: string;
}

export const validateAccountProfile = ServiceHandler<
  unknown,
  ValidateAccountProfileResponse
>({
  url: '/api/account/v1/account/profile/validate',
});

export const bulkApplyP250QuoteToAccounts = ServiceHandler({
  url: '/api/quote/v1/P250/bulk/quoteAccounts/:quoteId',
  method: 'POST',
});

export const redoRequestQuote = ServiceHandler<unknown, unknown>({
  url: 'api/quote/v1/:productType/retry/:quoteId',
  method: 'PUT',
});

export const searchQuotesV2 = ServiceHandler({
  url: '/api/quote/v1/search/dashboard',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(quoteSearchV2Mappings, params)),
});

export type UpdatePrimeXQuotePayload = Partial<
  {
    effectiveDate: string;
    endDate: string;
    aggregateLimit: number;
    deductible: number;
    waitingPeriod: number | null;
    retroactivePeriod?: number;
    isNotificationEnabled: boolean;
    retroActiveDate?: string;
    commissionRate: number;
  } & (
    | { coverages: any[]; dynamicFields: never }
    | { coverages: never; dynamicFields: DynamicFields }
  )
>;

export const updatePrimeXQuote = ServiceHandler<UpdatePrimeXQuotePayload>({
  url: '/api/quote/v1/:productType/edit/:quoteId',
  method: 'PUT',
});

interface UpdateQuoteCommissionPayload {
  commission: number;
  quoteId: string;
}

export const updateQuoteCommission =
  ServiceHandler<UpdateQuoteCommissionPayload>({
    url: '/api/quote/v1/action/commission/:quoteId',
    method: 'PUT',
  });

export const fetchUniqueAccountStats = ServiceHandler({
  url: '/api/quote/v1/search/uniqueAccounts',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(quoteServiceMappings, params)),
});

interface FetchPresetCoverageGroupsResponse {
  presetCoverageGroups: {
    name: string;
    groupName: string;
    order: number;
  }[];
}

export const fetchPresetCoverageGroups = ServiceHandler<
  unknown,
  FetchPresetCoverageGroupsResponse
>({
  url: '/api/rater/v1/presets/coverage-groups',
});

export const recalculateQuotePX = ServiceHandler({
  url: '/api/quote/v1/:productType/recalculate/:quoteId',
  method: 'POST',
});

export const submitPxQuoteForUWReview = ServiceHandler<
  Record<'reason', string>,
  unknown
>({
  url: '/api/quote/v1/:productType/request-review/:quoteId',
  method: 'PUT',
});

export const underwritePxQuote = ServiceHandler({
  url: '/api/quote/v1/:productType/uw/underwrite/:quoteId',
  method: 'PUT',
});

export const getEligibleProducts = ServiceHandler({
  url: '/api/account/v1/account/eligible-products',
});

export const toggleUnderwriterAction = ServiceHandler({
  url: '/api/quote/v1/:productType/uw/inbox/toggle-flag/:quoteId',
  method: 'PUT',
});

export const deletePxQuote = ServiceHandler({
  url: '/api/quote/v1/:productType/:quoteId',
  method: 'DELETE',
});

export const bypassCowbellFactorRules = ServiceHandler({
  url: 'api/quote/v1/:productType/uw/bypass-cf-rules/:quoteId',
  method: 'PUT',
});

interface AssignUWToQuotePayload {
  uwEmail: string;
  uwFullName: string;
}

export const assignUWToQuote = ServiceHandler<AssignUWToQuotePayload, unknown>({
  url: '/api/quote/v1/:productType/:quoteId/underwriter',
  method: 'PUT',
});

interface BoostRaterPayload {
  effectiveDate: Date;
  state: string;
  isRenewal: boolean;
}

export const getBoostRaterVersion = ServiceHandler<BoostRaterPayload, unknown>({
  url: '/api/quote/v1/boost-rater-version',
  method: 'POST',
});
