import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useSnackbar } from 'notistack';
// mui
import {
  Box,
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { setAgencyBindInfo, setRoute } from '../quote.reducer';
import { useSurplusContext } from '../../console/agencies/quotes/250/prime250.context';
import { withSurplus } from '../withSurplus';
import withRouter from '../../components/hocs/withRouter';
import { SimpleFooter } from '../../components/entry/SimpleFooter';
import { SurplusQuoteSummary } from './SurplusQuoteSummary';
import { LACompliance } from './LAComplaince';
import { MTCompliance } from './MTCompliance';
// actions
import { fetchP250QuotePageDetails } from '../QuotesService';
import { useActor } from '../../components/hooks/useActor';
import { REDACTED_FIELD } from '../../config/logrocket';

export const SurplusNoticeExternalSignature = compose(
  withSurplus(),
  withRouter
)(({ match }) => {
  const { heading1, heading2, card, ...classes } = useClasses();
  const actor = useActor();
  // handles fetching / refetching of quote
  const { loading, quote, unauthenticated } = useDependencies(match, actor);

  const [signature, setSignature] = React.useState(
    _.get(quote, 'firmographicData.signatureOnFile', false)
  );

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Container classes={classes} maxWidth="lg">
        <Box margin="2.5rem auto" maxWidth="13.9166666667rem">
          <img src="/logos/logo@3x.png" alt="cowbell logo" />
        </Box>
        <h1 className={`${REDACTED_FIELD} ${heading1}`}>
          Welcome {actor.fullName}
          to Cowbell Cyber
        </h1>
        <h2
          className={`${REDACTED_FIELD} ${heading2}`}
        >{`Quote ${quote.quoteNumber} for ${quote.companyName} `}</h2>
        <Divider className={classes.divider} />
        <Grid container spacing={4}>
          <Grid item md={12}>
            <SurplusQuoteSummary parentClasses={{ card }} quote={quote} />
          </Grid>
          <Grid item md={12}>
            {_.get(quote, 'firmographicData.state', '') === 'LA' && (
              <LACompliance
                parentClasses={{ card }}
                setSignature={setSignature}
              />
            )}
            {_.get(quote, 'firmographicData.state', '') === 'MT' && (
              <MTCompliance
                parentClasses={{ card }}
                setSignature={setSignature}
              />
            )}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <SimpleFooter />
      </Container>
      <Backdrop
        open={signature}
        className={classes.backdrop}
        style={{ backgroundColor: 'rgba(0, 0, 0, .75)' }}
      >
        <Container maxWidth="md">
          <Container maxWidth="md" style={{ textAlign: 'center' }}>
            <a href="https://cowbell.ensure">
              <img
                src="/logos/logo@2x.png"
                alt="Cowbell logo, and link to cowbell website"
                style={{ width: 300 }}
              />
            </a>
            <p className="canon-text text-center">
              Thank you for attesting to the Surplus Compliance.Your signature
              has already been uploaded to the platform. You can now login to
              the platform and bind the quote.
            </p>
          </Container>
        </Container>
      </Backdrop>
      <Backdrop
        open={unauthenticated}
        className={classes.backdrop}
        style={{ backgroundColor: 'rgba(0, 0, 0, .75)' }}
      >
        <Container maxWidth="md" style={{ textAlign: 'center' }}>
          <a href="https://cowbell.ensure">
            <img
              src="/logos/logo@2x.png"
              alt="Cowbell logo, and link to cowbell website"
              style={{ width: 300 }}
            />
          </a>
          <p className="canon-text text-center">
            Looks like you're not able to view this quote. If you believe you
            recieved this message in error, please go back to the email and
            click the "Review & Sign" link again.
          </p>
        </Container>
      </Backdrop>
    </>
  );
});

const useClasses = makeStyles(({ config, ...theme }) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.text.link,
  },

  heading1: {
    margin: 0,
    marginTop: '2.5rem',
    fontSize: config.textSizes.canon,
    lineHeight: 1,
    textAlign: 'center',
    color: 'white',
  },

  heading2: {
    marginTop: '1.3333333333rem',
    marginBottom: 0,
    fontSize: config.textSizes.paragon,
    fontWeight: 400,
    lineHeight: 1,
    textAlign: 'center',
    color: 'white',
  },

  card: {
    padding: '1.6666666667rem',
    border: '1px solid #707070',
    backgroundColor: 'rgba(38, 38, 46, .5)',
    color: 'white',
    fontSize: config.textSizes.tertia,
  },

  divider: {
    margin: '1.5rem 0',
    background: theme.palette.common.cowbellBlue,
  },
}));

const useDependencies = (match, actor) => {
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState({ firmographicData: {} });
  const [unauthenticated, setUnauthenticated] = useState(false);
  const { dispatch } = useSurplusContext();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    function handleQuoteError(error) {
      const status = _.get(error, 'response.status', '');

      if (status === 401) {
        setUnauthenticated(true);
      }

      setLoading(false);
      enqueueSnackbar('There was an error while retrieving the quote', {
        variant: 'success',
      });
    }

    // on first page render
    setLoading(true);

    fetchP250QuotePageDetails(match.params.id)
      .then(({ data }) => {
        setQuote(data);
        setLoading(false);
        dispatch(
          setAgencyBindInfo({
            surplus: {
              surplusLinesBrokerFirstName: actor.firstName,
              surplusLinesBrokerLastName: actor.lastName,
              surplusLinesBrokerEmail: actor.email,
              ..._.pick(data.firmographicData, [
                'surplusLinesNumber',
                'surplusLinesState',
                'signatureOnFile',
              ]),
            },
          })
        );
        dispatch(setRoute({ from: 'console' }));
      })
      .catch(handleQuoteError);

    // eslint-disable-next-line
  }, []);

  return { loading, quote, unauthenticated };
};
