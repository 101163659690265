import { blueGrey } from '@mui/material/colors';
import Color from 'color';
import { createColorStep } from '../../utils/colorUtils';

const config = {
  textSizes: {
    bigInch: '4rem',
    inch: '3rem', // 36
    bigCanon: '2.75rem', // 33
    nonpareil: '2.5rem', // 30
    canon: '2rem', // 24
    paragon: '1.6666666667rem', // 20
    greatPrimer: '1.5rem', // 18
    primer: '1.3333333333rem', // 16
    tertiaPrime: '1.20rem', // 15
    tertia: '1.1666666667rem', // 14
    normal: '1rem', // 12
    petite: '0.8333333333rem', // 10
    minion: '0.6666666667rem', // 8
  },

  fonts: {
    oswald: 'Oswald',
    stackSerif: '"Times New Roman", Times, Georgia, serif',
    stackSans:
      '"Roboto", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif',
    stackMono: '"Roboto", Courier, monospace',
    secondaryStackSans: '"Oswald", "Roboto", Helvetica, Arial, "Arial Unicode"',
    titillum:
      '"Roboto", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif',
  },
  weights: {
    light: 200,
    normal: 400,
    bold: 600,
  },
  colors: {
    // cowbell blues
    cowbellLight: '#2180e2',
    cowbellBlue: '#2180e2',
    powderBlue: '#b4d4f5',
    snackBlue: '#1C6ABA',
    cowbellLight1: '#6BA9EB',
    lightBlue: '#8fbff0',
    paleBlue: '#d9e9f9',
    // greys
    white: '#ffffff',
    lightWhite: '#EEF7E2',
    lightWhite1: '#eff7ff',
    white1: '#ececed',
    white2: '#e4e8ed',
    white3: '#f8f8f8',
    dark: '#27242C',
    greyWhite: '#9192a4',
    lightestGray: 'rgba(0,0,0,0.5)',
    grey1: '#2a2a34',
    grey2: '#c4c4c8',
    grey3: '#5d5f64',
    grey4: '#9AA0AE',
    grey5: '#929296',
    grey6: '#47474d',
    grey7: '#5c5c62',
    grey8: '#2a2c31',
    grey9: '#9d9d9f',
    grey10: '#a8a6a9',
    grey11: '#a3a3a6',
    black: '#02202c',
    black1: '#3d3b4a',
    lightGrey: '#f8f9f9',
    lightGrey1: '#f9f9f9',
    lightGrey2: '#e3e7ed',
    lightGrey3: '#bdbdbd',
    lightGrey4: '#e0e0e0',
    mediumGrey: '#a6a6a6',
    darkGrey: '#1f1f29',
    brownGrey: '#393841',
    bbb: '#E4E8ED',
    darkGreyLighter: '#26262e',
    eggWhite: '#FDFDFE',
    darkGreyActive: '#444353',
    darkGreyActive1: '#5F5F71',
    blueWhiteActive: '#EFF6FF',
    darkGreySoft: '#9aa0ae',
    darkGreyHard: '#8A8A8E',
    lightBlueGrey: blueGrey[500],
    blueGrey: '#2A2A34',
    blueWhiteHard: '#D6DEE8',
    almostWhite: '#c9cdd9',
    almostBlack: '#1a1a1a',
    cowbellAccent: '#6ac52a',
    cowbellGreen: '#bee6a2',
    cowbellGreen2: '#5eae27',
    cowbellGreenDarkGreen: '#45811d',
    green3: '#68cc99',
    darkGreen: 'rgb(63, 154, 141)',
    darkblack: '#000000',
    dark1: '#09090c',
    darkGrey1: '#17171a',
    darkGrey2: '#0d0d10',
    darkGrey3: '#5e5e5f',
    darkGrey4: '#343c53',
    darkGrey5: '#333340',
    paleGrey: '#eff6ff',
    paleGrey1: '#707070',
    greyBlack: '#121214',
    greyBlack1: '#4b5151',
    darkBlack: '#272727',
    greyBlackLight: '#0f0f0f',
    veryLightGrey: '#edf2f8',
    veryLightGrey1: '#D7E4F4',
    veryLightGrey2: '#D2D7E0',
    greyBorder: '#63625E',
    whiteBorder: '#D3D3DE',
    charcoal: '#303542',
    // blues
    softBlue: '#5ca2ea',
    darkCyan: '#b4cfd9',
    cyan: '#20658c',
    lightCyan: '#3fdad9',
    aquaMarine: '#007bea',
    lightAquaMarine: '#25a7a1',
    aquaMarine1: '#28b8c3',
    babyBlueGrey: '#eceff1',
    darkBlue: '#175595',
    darkerBlue: '#034360',
    cowbellCyan: '#092941',
    disabledRow: '#2A272C',
    blue1: '#2180e3',
    blue2: '#69a8e8',
    blue3: '#6ba9eb',
    blue4: '#2080e1',
    blue5: '#314052',
    yellow1: '#fbab45',
    purple: '#b183e5',
    brown: '#c78746',
    faintBrown: '#9ba0ae',
    lightNavy: '#113e68',
    // greens
    paleGreen: '#b8e986',
    green1: '#43ac50',
    snackGreen: '#49931B',
    green2: '#cce30e',
    green4: '#0f4529',
    green5: '#a2db74',
    lightGreen: '#f8fff4',
    florescentGreen: '#292E26',
    green6: '#6ac52b',
    // reds
    tomato: '#eb5e30',
    paleTomato: '#ff6736',
    ripeTomato: '#f53d00',
    error: '#ff0000',
    orangeRed: '#f95f53',
    textRed: '#fa8072',
    yellow: '#fbc000',
    badgeRed: '#e60000',
    brightYellow: '#ffcc58',
    darkRed: '#ff0000',
    stuck: '#830012',
    blood: '#A60917',
    bloodLight: '#A6091720',

    pink1: '#fbc5bf',

    softWhite: 'rgba(255, 255, 255, 0.2)',
    softBlack: 'rgba(0, 0, 0, 0.2)',

    // yellows
    canery: '#d9db3d',
    lightCanery: '#eaeb93',
    darkCanery: '#8e9009',
    snackYellow: '#FBC000',
    lightYellow: '#f9f3d9',
    chromeYellow: '#ebaa44',
    // oranges
    favoriteOrange: '#f48617',

    // custom
    critical: '#ca2b2f',
    elevated: '#fda942',
    good: '#6ac52a',
    tableRowDark: '#1F2833',
    tableRowLight: '#EAF8FF',
    gradient: {
      greens: createColorStep(Color('#7ed321').darken(0.25).hex()),
      blues: createColorStep('#4a90e2'),
    },
  },
} as const;

export default config;
