import {
  accountAndNadSearchMappings,
  accountSearchV2Mappings,
  accountServiceMappings,
} from '../accounts/accountServiceMappings';

import type { DynamicFormResponse } from '../form-engine/types';

import type { AccountDto, ProductType } from '../types';
import type { AccountTableInfoDto } from '../types/accounts/tables/account-table-info.dto';
import { mapCFKeys } from '../utility/CowbellFactorsMapping';
import {
  applyQueryConfig,
  serializeStandardResponse,
  ServiceHandler,
} from '../utils';
import { agencyLevelParamsSerializer } from './agency-level.params-serializer';
import { transformAgencyLevelResponse } from './agency-level.response-serializer';
import type { PageableResponse } from './serializers';
import { standardQuerySerializer } from './serializers';
import { serializeAccountIndustryCode } from './serializers/industry-code.serializers';
import type { SelectOption } from '../console/agencies/quotes/PrimeX/customizeQuote/reducer/types';
import type { AgencySearchDto } from '../types/agencies/dto/agency-search.dto';

export const searchAccounts = ServiceHandler<
  unknown,
  PageableResponse<AccountTableInfoDto>
>({
  url: '/api/account/v1/account/search',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(accountServiceMappings, params)),
});

export const getAccountDetails = ServiceHandler<unknown, AccountDto>({
  url: '/api/account/v1/account/:accountId',
  transformResponse: [serializeStandardResponse, serializeAccountIndustryCode],
});

export const getAccountRiskDetails = ServiceHandler({
  url: '/api/risk/v1/:accountId',
  transformResponse: [serializeStandardResponse, mapCFKeys],
});

export const regenerateAgencyBill = ServiceHandler({
  url: '/api/account/v1/account/agencyBill',
});

export const getOwnershipTypes = ServiceHandler<
  unknown,
  Array<SelectOption<string>>
>({
  url: '/api/account/v1/account/ownershipTypes',
});

export interface GetAgenciesResponse {
  content: AgencySearchDto[];
}

export const getAgencies = ServiceHandler<unknown, GetAgenciesResponse>({
  url: 'api/account/v1/agency/search',
});

interface RefreshAccountFirmoPayload {
  quoteId: string;
  agentEmail: string;
  product: ProductType;
  agencyId: string;
  isRenewalOrMigration?: boolean;
}

export const RefreshAccountFirmo = ServiceHandler<
  RefreshAccountFirmoPayload,
  any
>({
  url: '/api/account/v1/account/refresh/:accountId',
  method: 'PUT',
});

export interface GetAgencyLevelListingResponse {
  content: AgencyLevelListingTableRow[];
  totalElements: number;
}

export interface AgencyLevelListingTableRow {
  created: string;
  createdBy: string;
  id: string;
  lastModifiedBy: string;
  levelName: string;
  modified: string;
  qualifiers: RevenueBand[];
  ui_revenueBands: UIRevenueBand[];
}

export interface UIRevenueBand {
  maxRevenue: string;
  minRevenue: string;
  primaryProductType: string;
  secondaryProductType: string;
}

export interface RevenueBand {
  maxRevenue: number;
  minRevenue: number;
  primaryProductType: string;
  secondaryProductType: string;
}

export const getAgencyLevelListing = ServiceHandler<
  void,
  GetAgencyLevelListingResponse
>({
  url: 'api/account/v1/agency/levels',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(agencyLevelParamsSerializer, params)
    ),
  transformResponse: [serializeStandardResponse, transformAgencyLevelResponse],
});

export interface AddEditAgencyLevelPayload {
  levelName: string;
  qualifiers: RevenueBand[];
}

export const addAgencyLevel = ServiceHandler<
  AddEditAgencyLevelPayload,
  unknown
>({
  url: 'api/account/v1/agency/levels',
  method: 'POST',
});

export const editAgencyLevel = ServiceHandler<
  AddEditAgencyLevelPayload,
  unknown
>({
  url: 'api/account/v1/agency/levels/:id',
  method: 'PUT',
});

export const deleteAgencyLevel = ServiceHandler({
  url: 'api/account/v1/agency/levels/:id',
  method: 'DELETE',
});
export const updateSecurityContact = ServiceHandler({
  url: '/api/account/v1/account/securityContact/:accoundId',
  method: 'POST',
});

export const searchAccountsV2 = ServiceHandler({
  url: '/api/account/v1/account/search/dashboard',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(accountSearchV2Mappings, params)),
});

export const searchAccountsAndNad = ServiceHandler({
  url: '/api/account/v1/account/search/accounts-and-nad',
  paramsSerializer: (params) =>
    standardQuerySerializer(
      applyQueryConfig(accountAndNadSearchMappings, params)
    ),
});

interface ValidateAddressPayload {
  country: string;
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  zipCode: string;
}

export interface ValidateAddressResponse {
  verificationStatus: string;
  address: Record<string, string>;
  errorFields: string[];
  localizedErrorFieldNames: string[];
}

/* used for prime on UK address currently. WIll be used for US address too in future */
export const validateAddress = ServiceHandler<
  ValidateAddressPayload,
  ValidateAddressResponse
>({
  url: '/api/account/v1/address/validate',
  method: 'POST',
});

export const fetchAccountStats = ServiceHandler({
  url: 'api/account/v1/account/stats/status',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(accountServiceMappings, params)),
});

export const fetchQuickQuoteStats = ServiceHandler({
  url: 'api/account/v1/account/quick-quote-premium',
  paramsSerializer: (params) =>
    standardQuerySerializer(applyQueryConfig(accountServiceMappings, params)),
});

export const saveSecurityQuestions = ServiceHandler({
  url: '/api/account/v1/questions/add/:accountId',
  method: 'POST',
});

export const validatePrimeXQuestions = ServiceHandler({
  url: '/api/account/v1/account/profile/primex/validate',
});

export interface GetAvailableProductsResponse {
  products: {
    id: string;
    key: ProductType;
    name: string;
    type: string;
    productLine: ProductLine;
  }[];
}

export type ProductLine = {
  id: string;
  created: string;
  key: string;
  modified: string;
  name: string;
};

export const getAvailableProducts = ServiceHandler<
  unknown,
  GetAvailableProductsResponse
>({
  url: '/api/account/v1/products',
});

export type GetProductLinesResponse = {
  productLines: {
    id: string;
    key: string;
    name: string;
    prefix: string;
  }[];
};

export const getProductLines = ServiceHandler<unknown, GetProductLinesResponse>(
  {
    url: '/api/account/v1/product-lines',
  }
);

export const getDynamicForm = ServiceHandler<unknown, DynamicFormResponse>({
  url: '/api/account/v1/questions/:accountId/forms/:formId',
});

export const getSubmissionsDynamicForm = ServiceHandler<
  unknown,
  DynamicFormResponse
>({
  url: '/api/account/v1/questions/submission/:submissionId/forms/:formId',
});

export const getQuoteDynamicForm = ServiceHandler<unknown, DynamicFormResponse>(
  {
    url: '/api/account/v1/questions/quote/:quoteId/forms/:formId',
  }
);

export const saveDynamicFormFieldsWithKeys = ServiceHandler({
  url: '/api/account/v1/questions/:accountId/fields/key',
  method: 'POST',
});

export const saveDynamicFormFields = ServiceHandler({
  url: '/api/account/v1/questions/:accountId/fields',
  method: 'POST',
});

export const saveSubmissionDynamicFormFields = ServiceHandler({
  url: '/api/account/v1/questions/submission/:submissionId/fields',
  method: 'POST',
});
