import { Api } from '../../config/axiosConfig';
import { ServiceHandlerFactory } from '../../utils/serviceUtils';
import { accountServiceMappings } from '../../accounts/accountServiceMappings';
import { riskProfileServiceMappings } from './riskProfileServiceMappings';

export const fetchCarriers = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/carrier/search',
  },
  isCancelable: true,
  paramSchema: riskProfileServiceMappings,
});

export const fetchReinsurers = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/reinsurer/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});

export const fetchPremiumProcessors = ServiceHandlerFactory({
  axios: {
    url: 'api/account/v1/premiumprocessor/search',
  },
  isCancelable: true,
  paramSchema: accountServiceMappings,
});

export const fetchCarrierRiskProfiles = ServiceHandlerFactory({
  axios: {
    url: 'api/rater/v1/carrier/search',
  },
  paramSchema: riskProfileServiceMappings,
});

export const createCarrierRiskProfile = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/carrier',
    method: 'post',
  },
});

export const updateCarrierRiskProfile = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/carrier/:id',
    method: 'put',
  },
});

export const deleteCarrierRiskProfile = ServiceHandlerFactory({
  axios: {
    url: '/api/rater/v1/carrier/:id',
    method: 'delete',
  },
});

export const createCarrier = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/carrier',
    method: 'post',
  },
});

export const createReinsurer = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/reinsurer',
    method: 'post',
  },
});

export const createPremiumProcessor = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/premiumprocessor',
    method: 'post',
  },
});

export const updateReinsurer = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/reinsurer/:id',
    method: 'put',
  },
});

export const updatePremiumProcessor = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/premiumprocessor/:id',
    method: 'put',
  },
});

export const updateCarrier = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/carrier/hostname/:id',
    method: 'put',
  },
});

export const deleteCarrier = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/carrier/:id',
    method: 'delete',
  },
});

export const deleteReinsurer = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/reinsurer/:id',
    method: 'delete',
  },
});

export const deletePremiumProcessor = ServiceHandlerFactory({
  axios: {
    url: '/api/auth/v1/premiumprocessor/:id',
    method: 'delete',
  },
});

export function enterCarrier(id) {
  return Api.post(`/api/auth/v1/carrier/${id}/enter`);
}
export const getCarrierDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/carrier`,
  },
  isCancelable: true,
});

export const getReinsurerDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/reinsurer`,
  },
  isCancelable: true,
});

export const getPremiumProcessorDetails = ServiceHandlerFactory({
  axios: {
    url: `/api/account/v1/premiumprocessor`,
  },
  isCancelable: true,
});

export const setCarrierDetails = ServiceHandlerFactory({
  axios: {
    url: '/api/account/v1/carrier',
    method: 'put',
  },
  isCancelable: true,
});
