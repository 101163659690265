import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as Yup from 'yup';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Grid,
  DialogActions,
  DialogContent,
  FormHelperText,
  MenuItem,
  Select,
} from '@mui/material';
import { withStyles } from '@mui/styles';

// actions
import { useQueryClient } from '@tanstack/react-query';
import { getMyTeams } from '../../teams/TeamsService';
import { updateAgent } from '../../agencies/AgencyService';
// components
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import CbButton from '../../components/Buttons/CbButton';
import { RegisteredAgentAutoComplete } from './AgentsAutoComplete';
import { manageAPIError } from '../../utils';
import { AGENCY_ACCOUNTS } from '../../components/tables/table_constants';
import { useCowbellTranslations } from '../../i18n/translations';
import { REDACTED_FIELD } from '../../config/logrocket';

const styles = ({ config, palette }) => {
  return {
    container: {
      marginBottom: '1rem',
    },
    label: {
      fontSize: config.textSizes.tertia,
      padding: 0,
    },
    value: {
      fontSize: config.textSizes.tertia,
      color: palette.primary.contrastText,
      fontWeight: config.weights.bold,
    },
    select: {
      justifyContent: 'left',
      '&:focus': {
        borderRadius: 5,
      },
    },
    selectIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    selectDisabled: {
      background: palette.background.disabled,
    },
  };
};

const schema = Yup.object().shape({
  newAgent: Yup.string().label('This').required(),
});

export const AgencyUpdateAgent = withStyles(styles)(
  ({ classes, close, data }) => {
    const [error, setError] = useState('');
    const [stateError, setStateError] = useState('');
    const [defaultTeamOptions, setDefaultOptions] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useCowbellTranslations();

    const { handleSubmit, ...methods } = useForm({
      defaultValues: {
        newAgent: '',
        team: '',
      },
      resolver: yupResolver(schema),
    });

    const {
      getValues,
      setValue,
      watch,
      register,
      formState: { isSubmitting },
    } = methods;

    watch(['newAgent', 'team']);

    useEffect(() => {
      getMyTeams().then((resp) => {
        setDefaultOptions(resp.data.content);
      });
    }, []);

    const handleAgentsChange = useCallback(
      (selectedOption) => {
        setValue('newAgent', selectedOption);
        setValue('team', '');
        setStateError('');
        const { teams } = selectedOption.meta;

        if (_.isArray(teams) && teams.length === 0) {
          return setTeamOptions(defaultTeamOptions);
        }
        if (teams.length === 1) {
          return [setTeamOptions(teams), setValue('team', ...teams)];
        }
        return setTeamOptions(teams);
      },
      // eslint-disable-next-line
      [defaultTeamOptions]
    );

    const handleChange = (event) => {
      setValue(event.target.name, event.target.value);
      setStateError('');
    };

    const onSubmit = () => {
      const {
        policyContactFirstName,
        policyContactLastName,
        policyContactEmail,
        policyContactPhone,
        id,
        name,
      } = data;

      const { newAgent, team } = values;

      if (!team.id) {
        setStateError('Team is a required field.');
      } else {
        setStateError('');
        const payload = {
          accountId: id,
          accountName: name,
          contactFirstName: policyContactFirstName ?? '',
          contactLastName: policyContactLastName ?? '',
          contactEmail: policyContactEmail,
          contactPhone: policyContactPhone,

          agentEmail: newAgent.meta?.email,
          agentFirstName: newAgent.meta?.firstName,
          agentLastName: newAgent.meta?.lastName,
          agentPhone: newAgent.meta?.phone,
          team,
        };

        return updateAgent(payload)
          .then(() => {
            enqueueSnackbar(
              `Account ${payload.accountName} updated with ${t(
                'agent'
              ).toLowerCase()} ${payload.agentFirstName} ${
                payload.agentLastName
              }`,
              { variant: 'success' }
            );
            queryClient.invalidateQueries([AGENCY_ACCOUNTS]);
            close();
          })
          .catch((error) => {
            if (!payload.contactFirstName || !payload.contactLastName) {
              return setError(
                "Update this account's organization contact info and try again"
              );
            }

            setError(
              manageAPIError(error, 'An error has occured. Please try again')
            );
          });
      }
    };

    const values = getValues();

    const renderOptions = (option) => {
      let nextOption = option;

      if (typeof option === 'string') {
        nextOption = { label: option, value: option };
      }

      return (
        <MenuItem key={nextOption.name} value={nextOption}>
          {nextOption.name}
        </MenuItem>
      );
    };

    return (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid item md={4}>
                <InputLabelBase className={classes.label}>
                  Account Name
                </InputLabelBase>
              </Grid>
              <Grid item md={8}>
                <div className={`${REDACTED_FIELD} ${classes.value}`}>
                  {data.name}
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item md={4}>
                <InputLabelBase className={classes.label}>
                  Current {t('agent')}
                </InputLabelBase>
              </Grid>
              <Grid item md={8}>
                <div className={`${REDACTED_FIELD} ${classes.value}`}>
                  {_.isUndefined(data.agentEmail) || _.isEmpty(data.agentEmail)
                    ? `No ${t('agent')} Assigned`
                    : `${data.agentName} (${data.agentEmail})`}
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item md={4}>
                <InputLabelBase required className={classes.label}>
                  Select New {t('agent')}
                </InputLabelBase>
              </Grid>
              <Grid item md={8}>
                <RegisteredAgentAutoComplete
                  name="newAgent"
                  required
                  error={
                    !values.newAgent.value &&
                    _.get(methods.formState.errors, 'newAgent.message')
                  }
                  showLabel={false}
                  onChange={handleAgentsChange}
                  fetchOnMount
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item md={4}>
                <InputLabelBase required className={classes.label}>
                  Team
                </InputLabelBase>
              </Grid>
              <Grid item md={8}>
                <Select
                  variant="standard"
                  name="team"
                  required
                  {...register('team')}
                  onChange={handleChange}
                  value={values.team?.id}
                  classes={{
                    select: classes.select,
                    icon: classes.selectIcon,
                    disabled: classes.selectDisabled,
                  }}
                  disabled={!values.newAgent}
                  error={!values.team?.id && stateError}
                >
                  {teamOptions.map(renderOptions)}
                </Select>
                {!values.team?.id && (
                  <FormHelperText> {stateError}</FormHelperText>
                )}
              </Grid>
            </Grid>
            {error && (
              <FormHelperText className="api-text-error">
                {error}
              </FormHelperText>
            )}
          </DialogContent>
          <DialogActions>
            <CbButton buttonText="Cancel" action={close} styleName="cancel" />
            <CbButton
              buttonText={`Update ${t('agent')}`}
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              styleName="ctaButton"
            />
          </DialogActions>
        </form>
      </FormProvider>
    );
  }
);

export default AgencyUpdateAgent;
