import { mergeQueryKeys } from '@lukemorales/query-key-factory';

import { accounts } from './accounts';
import { quotes } from './quotes';
import { policies } from './policies';
import { submissions } from './submissions';
import { notes } from './notes';
import { cmail } from './cmail';
import { claims } from './claims';

export const queries = mergeQueryKeys(
  accounts,
  quotes,
  policies,
  submissions,
  notes,
  cmail,
  claims
);
