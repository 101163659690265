import type { AccountDto } from '../../../../../types';
import type {
  BoostRaterVersionResponse,
  GetPrime100CoveragesResponse,
  SocialEngineeringResponse,
  TransformedEligibleCoverageValuesResponse,
} from '../api/types';
import type { formatGlobalValues } from '../transformers/globalValues';

/* Provides a base type for all p100 quoting pages that we can extend upon */
export interface Prime100QuotePageState<
  T = Record<Prime100Coverages, CoverageState>
> {
  account: AccountDto;
  agencyId: string;
  disableRuleEvaluation?: boolean;
  boostRaterVersion: BoostRaterVersionResponse;
  effectiveDate: string;
  endDate: string;
  socialEngineering: SocialEngineeringResponse;
  coverageValues: TransformedEligibleCoverageValuesResponse;
  coverageData: GetPrime100CoveragesResponse;
  globalValues: ReturnType<typeof formatGlobalValues>;
  previousGlobalValues?: ReturnType<typeof formatGlobalValues>;
  coverageState: T;
  minEffectiveDate: Date;
  maxEffectiveDate: Date;
  additionalBrokerFee: number;
  submissionId?: string;
}

export enum Prime100Coverages {
  PUBLIC_RELATIONS_EXPENSE = 'PUBLIC_RELATIONS_EXPENSE',
  RESTORATION_OF_ELECTRONIC_DATA = 'RESTORATION_OF_ELECTRONIC_DATA',
  POST_BREACH_REMEDIATION = 'POST_BREACH_REMEDIATION',
  SOCIAL_ENGINEERING = 'SOCIAL_ENGINEERING',
  PCI_FINES_AND_PENALTIES = 'PCI_FINES_AND_PENALTIES',
  ADDITIONAL_INSURED = 'ADDITIONAL_INSURED',
  TELECOM_FRAUD = 'TELECOM_FRAUD',
  HARDWARE_REPLACEMENT_COSTS = 'HARDWARE_REPLACEMENT_COSTS',
  BI_EXTRA_EXPENSE_V5 = 'BI_EXTRA_EXPENSE_V5',
  EXTORTION_THREATS_AND_RANSOM_PAYMENT = 'EXTORTION_THREATS_AND_RANSOM_PAYMENT',
  COMPUTER_FUNDS_TRANSFER_FRAUD = 'COMPUTER_FUNDS_TRANSFER_FRAUD',
  SECURITY_BREACH_LIABILITY = 'SECURITY_BREACH_LIABILITY',
  BI_EXTRA_EXPENSE = 'BI_EXTRA_EXPENSE',
  EXTORTION_THREATS_AND_RANSOM_PAYMENT_V5 = 'EXTORTION_THREATS_AND_RANSOM_PAYMENT_V5',
  REGULATOR_DEFENSE_AND_PENALTIES = 'REGULATOR_DEFENSE_AND_PENALTIES',
  WEBSITE_MEDIA_CONTENT_LIABILITY = 'WEBSITE_MEDIA_CONTENT_LIABILITY',
  SECURITY_BREACH_EXPENSE = 'SECURITY_BREACH_EXPENSE',
  TRIA = 'TRIA',
}

export enum GlobalPropertyKeys {
  retroactivePeriod = 'retroactivePeriod',
  deductible = 'deductible',
  waitingPeriod = 'waitingPeriod',
  limit = 'limit',
}

export type GlobalPropertyNames = keyof typeof GlobalPropertyKeys;

export type GlobalValues = {
  [K in GlobalPropertyKeys]: {
    value: number;
    options: number[];
  };
};

export enum Prime100CoverageGroups {
  FIRST_PARTY_COVERAGES = 'First Party Coverages',
  THIRD_PARTY_COVERAGES = 'Third Party Coverages',
  ENDORSEMENTS = 'Endorsements',
  TRIA = 'TRIA',
}

export type CoverageVersion = {
  version: BoostRaterVersionResponse;
  name: string;
  tooltip?: string;
  errorMessage?: string;
  ordinal: number;
};

export type HydratedCoverageInfo = {
  [K in Prime100CoverageGroups]: CoverageState[];
};

export type CoverageState<T = any> = {
  id: Prime100Coverages;
  isMandatory?: boolean;
  isAvailable?: boolean;
  isSelected: boolean;
  nonStandardCoverage: boolean;
  limit: number;
  limits: number[];
  deductibles?: number[];
  deductible?: number;
  hasModal?: boolean;
  modalData: T;
  name: string;
  tooltip?: string;
  supplementalText?: string;
};

export type AdditionalInsureds = {
  address1: string;
  address2: string;
  businessName: string;
  city: string;
  naicsCode: string;
  naicsDescription: string;
  state: string;
  zipCode: string;
}[];

export enum SocialEngQuestions {
  IS_VERIFYING_BANK_ACCOUNTS = 'isVerifyingBankAccounts',
  IS_AUTHENTICATING_FUND_TRANSFERS = 'isAuthenticatingFundTransferRequests',
  IS_PREVENTING_UNAUTHORIZED_WIRE_TRANSFERS = 'isPreventingUnauthorizedWireTransfers',
  //due to really bad data. prefixing with underscores to avoid confusion
  __IS_AUTHENTICATING_FUNDS_TRANSFERS = 'isAuthenticatingFundsTransferRequest',
}

export const DF_SOCIAL_ENG_KEYS = {
  isVerifyingBankAccountsAnswer: SocialEngQuestions.IS_VERIFYING_BANK_ACCOUNTS,
  authenticateFundTransferRequests:
    SocialEngQuestions.IS_AUTHENTICATING_FUND_TRANSFERS,
  isPreventingUnauthorizedWireTransfersAnswer:
    SocialEngQuestions.IS_PREVENTING_UNAUTHORIZED_WIRE_TRANSFERS,
};
