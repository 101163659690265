import Moment from 'moment';
import { useAuth } from '../components/hooks/useAuth';

export const COWBELL_EPOCH = '2020-01-01T00:00:00+00:00';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const UI_DATE_FORMAT = 'MMM, DD YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export function utcISO(date: string | number): string {
  return Moment.utc(date).toISOString();
}

export function utcForAPI(date: string | number): string {
  return Moment.utc(date).format('YYYY-MM-DD');
}

// Converts a local date object to UTC. This is useful for inputs which rely on native JS objects (date picker). Use this when a date from a select input needs to be sent to BE and it represents a UTC/GMT time
// (e.g: 5/11/2024 PST -> 5/11/2024 UTC)
export function convertUTCDateToLocal(date: Date): Date {
  return Moment(Moment.utc(date).format('YYYY-MM-DD')).toDate();
}

export function utcToNativeDate(date: Date): Date {
  if (typeof date.getTime !== 'function') {
    throw new Error(
      `param "date" (${date}) provided to "utcAsNativeDate" is not a valid date`
    );
  }

  const milliseconds = date.getTime();
  const TimezoneOffsetAsMilliseconds = date.getTimezoneOffset() * 60 * 1000;

  return new Date(milliseconds + TimezoneOffsetAsMilliseconds);
}

/**
 * Converts a date to, or from, UTC by adding/removing the timezone offset.
 * You can use this to convert a date from a native input (these are always local) and send it to the BE as UTC.
 *
 * @param {string | number | date} [dateToConvert] - The date desired to be converted
 * @param {string} [parseAs] - The desired parsing mode.
 * @return {date} The converted date.
 *
 * @example
 *     convertDateUTC('2022-10-02')
 *     convertDateUTC(unixTimeStamp * 1000)
 *     convertDateUTC(dateObj)
 */
export function convertDateUTC(
  dateToConvert: string | number | Date,
  parseAs: 'local' | 'UTC'
): Date {
  const date = new Date(dateToConvert);
  let convertedDate;

  if (parseAs == 'UTC') {
    convertedDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  if (parseAs == 'local') {
    convertedDate = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }

  return convertedDate ?? date;
}

export const fourteenDaysInPast = Moment().subtract(14, 'days');
export const thirtyDaysInFuture = Moment().add(30, 'days');
export const fortyFiveDaysInFuture = Moment().add(45, 'days');
export const sixtyDaysInFuture = Moment().add(60, 'days');
export const oneYearInpast = Moment().subtract(365, 'd');
export const sevenDaysInpast = Moment().subtract(7, 'd');
export const threeMonthsInPast = Moment().subtract(3, 'months');

export const useDetermineEndorsementRestrictions = (
  policyEffectiveDate: Date | number,
  policyEndDate: Date | number
) => {
  const { isCowbellUserJumped } = useAuth();

  const policyEffectiveDateUTC = convertDateUTC(policyEffectiveDate, 'UTC');
  const policyEndDateUTC = convertDateUTC(policyEndDate, 'UTC');

  if (isCowbellUserJumped) {
    return {
      minEndorsementStartDate: policyEffectiveDateUTC,
      maxEndorsementEndDate: policyEndDateUTC,
    };
  }

  return {
    minEndorsementStartDate: Moment.max(
      Moment(policyEffectiveDateUTC),
      fourteenDaysInPast
    ).toDate(),
    maxEndorsementEndDate: Moment.min(
      Moment(policyEndDateUTC),
      thirtyDaysInFuture
    ).toDate(),
  };
};

export const durationOfDaysFromToday = (date: Date | Moment.MomentInput) => {
  return Moment.duration(
    Moment.utc().startOf('day').diff(Moment.utc(date))
  ).asDays();
};

interface ToUniversalDateOptions {
  placeholder?: string;
  raw?: boolean;
  format?: string;
}

export const FORMAT_DATE_AND_TIME = 'MMM. DD, YYYY h:mm A';
export const FORMAT_DATE = 'll';

const defaultDateOptions = {
  format: FORMAT_DATE,
  placeholder: '-',
};

export const toUniversalDate = (
  date: Date | string | number | null | undefined | Moment.Moment,
  options?: ToUniversalDateOptions
) => {
  const _options = { ...defaultDateOptions, ...options };

  if (!date) {
    return _options.placeholder;
  }

  if (Moment.isMoment(date)) {
    return date.format(_options.format);
  }

  return Moment(date).format(_options.format);
};

export const toUniversalUtcDate = (
  date: Date | Moment.MomentInput | string | number | null | undefined,
  options?: ToUniversalDateOptions
) => {
  const utcMomentInstance = date ? Moment.utc(date) : null;
  return toUniversalDate(utcMomentInstance, options);
};

export const SECOND_MS = 1000;
