import _ from 'lodash';
import { withSnackbar } from 'notistack';
// react
import React from 'react';
// mui
import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import { InputLabelBase } from '../../components/inputs/InputLabelBase';
import withRouter from '../../components/hocs/withRouter';
import {
  FORMAT_DATE_AND_TIME,
  toUniversalUtcDate,
} from '../../utils/date.utils';
import { moneyFormat2Decimals } from '../../utils/appUtils';
import { REDACTED_FIELD } from '../../config/logrocket';

export const SurplusQuoteSummary = withSnackbar(
  withRouter(({ parentClasses, quote, from, live = false }) => {
    const classes = useClasses({ from, live });

    return (
      <>
        <div className={classes.header}>
          <h3 className={`contrast-text ${classes.heading}`}>QUOTE SUMMARY</h3>
          <h3 className={`contrast-text ${classes.heading}`}>
            {quote.quoteNumber}
          </h3>
        </div>
        <Card className={parentClasses.card}>
          <Grid container spacing={8}>
            <Grid item md={6}>
              <div className="flex--spaced">
                <InputLabelBase
                  className={`${classes.inputLabelCustom} ${classes.blueLabels}`}
                >
                  NAME
                </InputLabelBase>
                <span className={`${REDACTED_FIELD} ${classes.blueLabels}`}>
                  {quote.companyName}
                </span>
              </div>
              <div className="flex--spaced" style={{ marginBottom: '1.5rem' }}>
                <InputLabelBase
                  className={`${classes.inputLabelCustom} ${classes.blueLabels}`}
                >
                  QUOTE NUMBER
                </InputLabelBase>
                <span className={classes.blueLabels}>{quote.quoteNumber}</span>
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  Quote Effective
                </InputLabelBase>
                {`${toUniversalUtcDate(quote.effectiveDate)} 12:01 AM`}
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  Quote Expires
                </InputLabelBase>
                {toUniversalUtcDate(quote.expiresOn, {
                  format: FORMAT_DATE_AND_TIME,
                })}
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  Quote Created
                </InputLabelBase>
                {toUniversalUtcDate(quote.created, {
                  format: FORMAT_DATE_AND_TIME,
                })}
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="flex--spaced">
                <InputLabelBase
                  className={`${classes.inputLabelCustom} ${classes.whiteLabels}`}
                >
                  Total Premium (WITHOUT FEES & TAXES)
                </InputLabelBase>
                <span className={classes.whiteLabels}>
                  {moneyFormat2Decimals(quote.premium)}
                </span>
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  MGA Fee
                </InputLabelBase>
                {moneyFormat2Decimals(_.get(quote, 'mgaFee', 0))}
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  Surplus Lines Tax
                </InputLabelBase>
                {moneyFormat2Decimals(
                  _.get(quote, 'quoteResponse.surplusLineTax', 0)
                )}
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  Surplus Stamping Fees
                </InputLabelBase>
                {moneyFormat2Decimals(
                  _.get(quote, 'quoteResponse.surplusStampingFee')
                )}
              </div>
              <div className="flex--spaced">
                <InputLabelBase className={classes.inputLabelCustom}>
                  Surplus Compliance By
                </InputLabelBase>
                {quote.agencyName}
              </div>
              <div className="flex--spaced">
                <InputLabelBase
                  className={`${classes.inputLabelCustom} ${classes.blueLabels}`}
                >
                  FINAL Premium
                </InputLabelBase>
                <span className={classes.blueLabels}>
                  {moneyFormat2Decimals(quote.totalPremium)}
                </span>
              </div>
            </Grid>
          </Grid>
        </Card>
      </>
    );
  })
);

const useClasses = makeStyles(({ config }) => ({
  heading: {
    fontWeight: config.weights.bold,
    lineHeight: 1.38,
    marginTop: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputLabelCustom: {
    padding: '0 0 0.5rem 0',
  },
  whiteLabels: {
    fontSize: config.textSizes.greatPrimer,
    color: config.colors.white,
    fontWeight: config.weights.bold,
  },
  blueLabels: {
    fontSize: config.textSizes.greatPrimer,
    color: config.colors.cowbellBlue,
    fontWeight: config.weights.bold,
  },
}));
