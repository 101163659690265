export const reasons = [
  { label: 'Class of Business', value: 'Class of Business' },
  {
    label: 'Poor Cyber Security Controls',
    value: 'Poor Cyber Security Controls',
  },
  { label: 'BOR', value: 'BOR' },
  { label: 'Poor Claims History', value: 'Poor Claims History' },
  { label: 'Duplicate Quote ', value: 'Duplicate Quote ' },
  { label: 'Error', value: 'Error' },
  { label: 'Fill-in option', value: 'Fill-in option' },
];

export const accountReasons = [
  { label: 'Claims history', value: 'Claims history' },
  { label: 'Nature of Operations', value: 'Nature of Operations' },
  { label: 'Poor Controls', value: 'Poor Controls' },
  { label: 'Size is too Big', value: 'Size is too Big' },
  { label: 'Fill-in option', value: 'Fill-in option' },
];

export const submissionReasons = [
  { label: 'Claims history', value: 'CLAIMS_HISTORY' },
  { label: 'Nature Of Operations', value: 'NATURE_OF_OPERATIONS' },
  { label: 'Poor Controls', value: 'POOR_CONTROLS' },
  { label: 'Size is too big', value: 'SIZE_TOO_BIG' },
  { label: 'Fill-in option', value: 'FILL_IN_OPTION' },
];
