import { createQueryKeys } from '@lukemorales/query-key-factory';
import Moment from 'moment';
import { getAllMessages } from '../api/claims/claims-bullpen.api';
import { getData } from '../utils/functional/fp';

// This intentionally is hardcoded to display specific data for bullpens badge
const notificationFilter = {
  before: Moment().endOf('day').unix(),
  after: Moment().subtract(5, 'year').startOf('day').unix(),
  f: { claimNumber: { not_exists: 'true' } },
  size: '1',
};

export const claims = createQueryKeys('claims', {
  listClaimsFiles: (filters: Record<string, unknown>) => ({
    queryKey: [{ filters }],
    queryFn: () => getAllMessages({ params: filters }).then(getData),
  }),
  notificationBadge: () => ({
    queryKey: ['bullepenBadge'],
    queryFn: () => getAllMessages({ params: notificationFilter }).then(getData),
  }),
});
