import React from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import { withStyles } from '@mui/styles';

import CBButton from '../../../components/Buttons/CbButton';
import { toggleModalDirect } from '../../../utils/storeUtils';
import { REDACTED_FIELD } from '../../../config/logrocket';

// components

const SendAccountingConfirmation = ({ classes, data }) => {
  const clickHandler = React.useCallback(() => {
    toggleModalDirect('SendAccountingConfirmation', false);
  }, []);

  return (
    <section>
      <DialogContent classes={{ root: classes.container }}>
        <p className={classes.header}>
          Invoice for <span>Policy# {data.policyNumber}</span> has been sent to{' '}
          <span>
            {data.first} {data.last}
          </span>{' '}
          at <span className={REDACTED_FIELD}>{data.email}</span>.
        </p>
      </DialogContent>
      <DialogActions>
        <CBButton
          action={clickHandler}
          styleName="ctaButton"
          buttonText="OK"
          data-qa="ok"
        />
      </DialogActions>
    </section>
  );
};

const styles = ({ config, palette }) => {
  return {
    header: {
      fontSize: '1.33rem',
      lineHeight: 1.3,
      color: palette.primary.contrastText,
      fontFamily: config.fonts.stackSans,
      margin: '0 !important',
      textAlign: 'center',
      '& span': {
        fontWeight: config.weights.bold,
      },
    },
    container: {
      width: '50rem',
    },
  };
};

export default withStyles(styles)(SendAccountingConfirmation);
