import { CleaveBase } from './CleaveBase';
import { TextFieldBase } from './TextFieldBase';
import { REDACTED_FIELD } from '../../config/logrocket';

export const PhoneField = ({ InputProps = {}, ...props }) => {
  return (
    <TextFieldBase
      InputProps={{
        ...InputProps,
        inputComponent: CleaveInput,
      }}
      className={REDACTED_FIELD}
      {...props}
    />
  );
};

const CleaveInput = ({ inputRef, ...props }) => {
  return (
    <CleaveBase
      ref={inputRef}
      options={{
        delimiters: ['(', ') ', '-', '-'],
        blocks: [0, 3, 3, 4],
      }}
      {...props}
    />
  );
};
