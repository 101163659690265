import LogRocket from 'logrocket';
import { ENVIRONMENTS } from '../../types/common/env.dto';
import { getEnvironment } from '../../api/AuthService';

export async function initializeLogRocket() {
  try {
    const envResp = await getEnvironment();
    if (envResp.data?.envName == ENVIRONMENTS.STAGING_ENV_ID) {
      LogRocket.init('i0bntq/cowbell', {
        dom: {
          inputSanitizer: 'lipsum',
          privateClassNameBlocklist: [REDACTED_FIELD],
        },
      });
    }
  } catch (err) {
    console.error('LogRocket failed to initialize');
    console.error(err);
  }
}

/**
 * A constant representing a redacted field identifier.
 * This is used to mark fields that should be redacted in logs or screen replay in LOGROCKET.
 */
export const REDACTED_FIELD = 'cb_redacted_field';
