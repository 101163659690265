import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { compose } from 'redux';
import { IconButton } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Numeral from 'numeral';
import { EnhancedTable } from '../../../../components/tables/tables-new/variations/enhanced.table';
import { TableContext } from '../../../../components/tables/tables-new/base/context.table';
import { ActionBar } from '../../../../components/tables/tables-new/base/action-bar.table';
import { TableFooter } from '../../../../components/tables/tables-new/base/footer.table';

import {
  getBoolValueText,
  moneyFormat,
  moneyFormat2Decimals,
} from '../../../../utils/appUtils';
import { toUniversalUtcDate } from '../../../../utils/date.utils';

const noop = () => null;

const styles = ({ palette }) => ({
  presetName: {
    color: palette.common.cowbellGreen2,
    textTransform: 'capitalize',
  },

  deleteIcon: {
    margin: '0 auto',
    color: palette.error.main,

    '& svg:hover': {
      color: palette.error.main,
    },
  },
});

const useRowStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.background.customTable,
    borderBottom: `15px solid ${palette.background.modal}`,
    borderRadius: 0,
  },
}));

const useCellStyles = makeStyles(({ palette }) => ({
  root: {
    paddingTop: '0.58rem',
    paddingBottom: '0.58rem',
    color: palette.primary.contrastText,
  },
}));

const useHeadStyles = makeStyles(({ palette }) => ({
  root: {
    '& tr': {
      border: 0,
    },

    '& th': {
      background: palette.background.modal,
      color: palette.primary.contrastText,

      '& span': {
        fontWeight: 400,
      },
    },
  },
}));

// ui
export const CartList = compose(withStyles(styles))(
  ({ classes, accountId, data, handleRemoveQuote }) => {
    const rowStyles = useRowStyles();
    const cellStyles = useCellStyles();
    const headStyles = useHeadStyles();

    const context = useMemo(
      () => ({
        accountId,
        classes,
        handleRemoveQuote,
        styles: {
          row: rowStyles,
          cell: cellStyles,
          head: headStyles,
        },
      }),
      [accountId, cellStyles, classes, handleRemoveQuote, headStyles, rowStyles]
    );

    return (
      <>
        <EnhancedTable
          id="250quotes"
          columns={columns}
          context={context}
          data={data}
        >
          <ActionBar render={noop} />
          <TableFooter show={false} />
        </EnhancedTable>
      </>
    );
  }
);

// replace with the new columns
export const columns = [
  {
    name: 'quoteNumber',
    label: 'Quote Number',
    sort: false,
    Head: {
      C: () => (
        <span>
          Quote <br />
          Number
        </span>
      ),
    },
  },
  {
    name: 'presets',
    label: 'Coverage Selection',
    sort: false,
    Head: {
      align: 'center',
      C: () => (
        <span>
          Coverage <br /> Selection
        </span>
      ),
    },
    Cell: {
      align: 'center',
      C: ({ row }) => {
        const { classes } = useContext(TableContext);
        return (
          <span className={classes.presetName}>
            {row?.calculatorGeneratedData?.presets ??
              _.get(row, 'presetName', '').toLowerCase()}
          </span>
        );
      },
    },
  },
  {
    name: 'aggregateLimit',
    label: 'Aggregate Limit',
    sort: false,
    Head: {
      align: 'right',
      C: () => (
        <span>
          Aggregate <br /> Limit
        </span>
      ),
    },
    Cell: {
      align: 'right',
      width: 100,
      C: ({ row }) => {
        const { classes } = useContext(TableContext);
        return (
          <span className={classes.textColor}>
            {row.calculatorGeneratedData
              ? Numeral(row.calculatorGeneratedData.aggregateLimit).format(
                  '$0,00a'
                )
              : null}
          </span>
        );
      },
    },
  },
  {
    name: 'deductible',
    label: 'Deductible Amount',
    sort: false,
    Head: {
      align: 'right',
      C: () => {
        const { classes } = useContext(TableContext);
        return <span className={classes.textColor}>Deductible Amount</span>;
      },
    },
    Cell: {
      align: 'right',
      width: 100,
      C: ({ row }) => {
        const { classes } = useContext(TableContext);
        const liabilityCosts = _.first(
          row.calculatorGeneratedData.coverages,
          0
        );

        return (
          <span className={classes.textColor}>
            {liabilityCosts ? moneyFormat(liabilityCosts.deductible) : null}
          </span>
        );
      },
    },
  },
  {
    name: 'referToUnderwriter',
    label: 'Underwriter Referral',
    sort: false,
    Head: {
      align: 'center',
      C: () => (
        <span>
          Underwriter <br />
          Referral
        </span>
      ),
    },
    Cell: {
      align: 'center',
      C: ({ row }) =>
        getBoolValueText(row.calculatorGeneratedData.referToUnderwriter),
    },
  },
  {
    name: 'premium',
    label: 'Indicated Pricing',
    sort: false,
    Head: {
      align: 'right',
      C: () => (
        <span>
          Indicated <br />
          Pricing
        </span>
      ),
    },
    Cell: {
      align: 'right',
      width: 120,
      C: ({ row }) => {
        const premium = _.get(
          row,
          'calculatorGeneratedData.totalPremium',
          null
        );

        if (row.referToUnderwriter) {
          return 'Referral';
        }

        return premium ? (
          <span style={{ fontWeight: 700 }}>
            {moneyFormat2Decimals(premium)}
          </span>
        ) : (
          premium
        );
      },
    },
  },
  {
    name: 'calculatorGeneratedData.effectiveDate',
    label: 'Coverage Start Date',
    sort: false,
    Head: {
      align: 'center',
    },
    Cell: {
      align: 'center',
      width: 100,
      C: ({ row }) => {
        return (
          <span style={{ fontWeight: 700 }}>
            {toUniversalUtcDate(row.calculatorGeneratedData.effectiveDate)}
          </span>
        );
      },
    },
  },
  {
    name: 'actions',
    label: 'Delete Quote',
    canToggle: false,
    sort: false,
    Head: {
      align: 'center',
    },
    Cell: {
      align: 'center',
      width: 50,
      C: ({ row }) => {
        const { classes, handleRemoveQuote } = useContext(TableContext);
        return (
          <IconButton
            className={classes.deleteIcon}
            onClick={handleRemoveQuote(row.id)}
            size="small"
          >
            <HighlightOffIcon />
          </IconButton>
        );
      },
    },
  },
];
