import {
  ServiceHandler,
  applyQueryConfig,
  responseStringTransformer,
} from '../../utils';
import type { GetClaimsFilesResponse } from './responses/GetClaimsFilesResponse';
import { commonDateParamMappings } from '../common/mappings';
import {
  standardQuerySerializer,
  serializeElasticPageableTokenResponse,
} from '../serializers';

const claimFilesServiceMappings = [
  ...commonDateParamMappings,
  { path: 'page' },
  { path: 'search' },
  { path: 'size' },
  { path: 'searchAfter' },
  { path: 'pageRequested' },
  {
    paramKey: 'sort',
    transform: ({ orderBy, order }: { orderBy: string; order: string }) => {
      if (orderBy && order) {
        return `${orderBy},${order}`;
      }
      return null;
    },
  },
  { path: 'f', paramKey: 'filter' },
];

/**
 * Serializes the query parameters for quote search.
 * @param params - The query parameters.
 * @returns The serialized query parameters.
 */
function bullpenSearchQuerySerializer(params: Record<string, unknown>) {
  return applyQueryConfig(claimFilesServiceMappings, params);
}

/**
 * Fetches all messages with the given query parameters.
 */
export const getAllMessages = ServiceHandler<unknown, GetClaimsFilesResponse>({
  url: '/api/claimsmgmt/v1/claimsFile',
  paramsSerializer: (params) =>
    standardQuerySerializer(bullpenSearchQuerySerializer(params)),
  transformResponse: [
    responseStringTransformer,
    serializeElasticPageableTokenResponse,
  ],
});

export type AttachToClaimPayload = {
  diaryTitle: string;
  diaryText: string;
  claimsFileId: string;
  claimNumber: string;
};

export const attachToClaim = ServiceHandler<AttachToClaimPayload, unknown>({
  url: '/api/claimsmgmt/v1/claimsFile/attach-claim',
  method: 'POST',
});

export const bulkDeleteBullpen = ServiceHandler<string[], unknown>({
  url: '/api/claimsmgmt/v1/claimsFile/',
  method: 'DELETE',
});
